<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required,email , maxLength,minLength,minValue,numeric,requiredIf } from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
import Multiselect from 'vue-multiselect'
import { getDropDownElementsByTypeApi, fetchRoomsByBlocListApi} from "@/api/common";
import { emailFormatter} from "@/helpers/functions";
  import Swal from "sweetalert2";

export default {

    page: {
        title: "Employes",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {Multiselect},
    props:['selectedEmp','employe'],

    data() {
      return {
      tableData: [],
      bases:[],
      strList:undefined,
      title: "Nouveau Employe",
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      blocs:[],
      bloc:[],
      rooms:[],
      room:[],

     Emp_info: {
        Emp_first_name:'',
        Emp_last_name:'',
        Emp_sexe:'',
        Emp_phone:'',
        Emp_mobile:'',
        Emp_email:'',
        Emp_business_address:'',
        Emp_n_cin:'',

        Emp_n_passport:'',
        Emp_marital:'',
        Emp_employee_number:'',
        Emp_analytic_account:'',
        Emp_structure_id:'',
        Emp_is_externe:'',
        Emp_is_user : '',
        Emp_post : '',
        nationality :'',
        room:'',
        bloc:''
      },
      strType : [
                {text:'Direction',value:1},
                {text:'Division',value:2},
                {text:'Services',value:3},
                {text:'Service',value:4},
                {text:'Section',value:5},
            ],

      structures:[],
        etats:[
          {text:'Marié',value:"Marié"},
          {text:'divorcé',value:"divorcé"},
          {text:'célibataire',value:"célibataire"},
          {text:'veuf',value:"veuf"}
        ],
         sexes:[
            {text:'Homme',value:1},
            {text:'Femme',value:0}
        ],
      posts:[],
      post:[],
      structure:[],
      nationalitys:[],
      disabledAdd : false,
      disabledEdit : true,
      disabledDelete : true,
      disabled : true,
      disabledCancel : true,
      lastSelected : null,
      add : false,
      edit : false,
      destroy : false,
      send : false,
      have : false,
      resetForm:false,
    };
  },
    validations: {
      strList :{required},
      Emp_info:{
        Emp_first_name:{ required },
        Emp_last_name:{ required },
        Emp_sexe:{ required },
        Emp_phone:{ required, numeric, maxLength:maxLength(10),minLength:minLength(9)},
        Emp_email:{ 
          required,
          email: (val) => email(emailFormatter(val)),
        },
        Emp_business_address:{ required },
        Emp_n_cin:{ 
                required: requiredIf(function() {
                    return this.Emp_info.Emp_n_passport ==''
                }),        
        },

        Emp_n_passport:{ 
                required: requiredIf(function() {
                    return this.Emp_info.Emp_n_cin ==''
                }),        
        },
        Emp_marital:{ required },
        Emp_employee_number:{ required },
        nationality:{ required : false},
        Emp_post:{ required },
        Emp_structure_id:{ required },

        //Emp_is_externe:{},
      }
    },
  watch:{
    add(){
      this.addBtn()
      this.resetForm = false;
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.deleteEmp()
    },
  selectedEmp(){
        this.have = false;
      if(this.selectedEmp.length != 0 ){
        this.addSelectedRow(this.selectedEmp)
      }else{
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledDelete = true;
        this.disabledEdit   = true;
        this.disabledCancel = true;
        this.resetForm = true;
        this.clearForm();
      }
    }  },
  mounted(){
   // this.getLifeBases();
    this.getNationalitys(),
    this.getPost(),
    this.getblocs()
},

 methods:{
    addSelectedRow(val){
      this.lastSelected   = val;
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledDelete = false;
      this.disabledEdit   = false;
      this.disabledCancel = true;
      this.Emp_info.uuid                 = val.uuid;
      this.Emp_info.Emp_first_name       = val.first_name;
      this.Emp_info.Emp_last_name        = val.last_name;
      this.Emp_info.Emp_sexe             = val.sexe;
      this.Emp_info.Emp_phone            = val.phone;
      //this.Emp_info.Emp_mobile         = val.mobile;
      this.Emp_info.Emp_email            = val.email;
      this.Emp_info.Emp_business_address = val.business_address;
      this.Emp_info.Emp_n_cin            = val.n_cin;
      this.Emp_info.Emp_n_passport       = val.n_passport;
      this.Emp_info.Emp_marital          = val.marital;
      this.Emp_info.Emp_employee_number  = val.employee_number;
      this.Emp_info.Emp_analytic_account = val.analytic_account;
      this.Emp_info.bloc = val.bloc;
      this.Emp_info.room = val.room;
      this.bloc = val.bloc;
      this.room = val.room;
      this.structure = val.structure;
      this.post = val.postInfos;
      var sil                            = (val.structure_id).length;
      switch (sil) {
          case 3:
              this.strList = 1;
              break;
          case 6:
              this.strList = 2;
              break;
          case 9:
              this.strList = 3;
              break;
          case 12:
              this.strList = 4;
              break;
          case 15:
              this.strList = 5;
              break;
      
          default:
              break;
      }
      this.getStr();
      this.Emp_info.Emp_structure_id = val.structure_id; 
      this.Emp_info.Emp_is_externe   = val.is_externe;  
      this.Emp_info.Emp_is_user      = val.is_user; 
      this.Emp_info.nationality      = val.nationality;                
      this.Emp_info.Emp_post         = val.post;      
      this.Emp_info.superior_id        = val.superior_id ;      

      
    },
    addBtn(){
      this.have = true;
      this.add            = false;
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      this.clearForm();
    },
    editBtn(){
      this.have         = true;
      this.add          = false;
      this.edit         = false;
      this.disabledAdd  = true;
      this.disabledEdit = true;
      this.disabled     = false;
      this.disabledCancel = true;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledCancel = true;
      if(this.lastSelected != null){
        this.disabledEdit   = false;
        this.addSelectedRow(this.lastSelected);
      }
      this.disabledAdd    = false;
    },
    getNationalitys(){
         getDropDownElementsByTypeApi('country')

          .then((res) => {
              this.nationalitys = res.data.list;
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },
     
    getPost(){
         getDropDownElementsByTypeApi('employee_function')

          .then((res) => {
              this.posts = res.data.original.list;
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },
     getStr(){
        var urlStr;
        switch (this.strList) {
            case 1:
                urlStr = "/organisation/hierarchy/direction/getDirectionsList";
                break;
            case 2:
                urlStr = "/organisation/hierarchy/division/getDivisionsList";
                break;
            case 3:
                urlStr = "/organisation/hierarchy/departement/getDepartementsList";
                break;
            case 4:
                urlStr = "/organisation/hierarchy/service/getServicesList";
                break;
            case 5:
                urlStr = "/organisation/hierarchy/section/getSectionsList";
                break;
        }
          let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            })
            this.$http.get(urlStr)
            .then((res) => {
                this.structures = res.data;
                
                loader.hide();
            }).catch((error) => {
                loader.hide();
            this.$toast.error(error.message);
            })
            .finally(() => {});
    },

    clearForm(){
            this.Emp_info.uuid                 = "";
            this.Emp_info.Emp_first_name       = "";
            this.Emp_info.Emp_last_name        = "";
            this.Emp_info.Emp_sexe             = "";
            this.Emp_info.Emp_phone            = "";
            //this.Emp_info.Emp_mobile         = this.selectedEmp.mobile;
            this.Emp_info.Emp_email            = "";
            this.Emp_info.Emp_business_address = "";
            this.Emp_info.Emp_n_cin            = "";
            this.Emp_info.Emp_n_passport       = "";
            this.Emp_info.Emp_marital          = "";
            this.Emp_info.Emp_employee_number  = "";
            this.Emp_info.Emp_analytic_account = "";
            this.Emp_info.Emp_structure_id     = ""; 
            this.Emp_info.Emp_post             = "";
            this.Emp_info.Emp_is_externe       = ""; 
            this.Emp_info.Emp_is_user          = "";   
            this.Emp_info.nationality          = "";  
            this.superior_id                     = "";
            this.strList = "";         
            this.Emp_info.room=""     ;
            this.Emp_info.bloc=""     ;
            this.room=""     ;
            this.bloc=""     ;
            this.structure=""     ;
            this.post=""     ;
    },
    getEmp(){
      
      this.$http.get('/master_data/referentiels/employes')
      .then(response => {
          this.employe = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refresEmp(){
      this.getEmp();
    },

    getblocs(){
      this.$http.post('/infrastructure/blocs/list')
        .then(response => {
          this.blocs = response.data.original.list;
        })
        .catch(error => console.log(error))
    },
    getrooms(bloc){
      fetchRoomsByBlocListApi(bloc)
        .then(response => {
          this.rooms = response.data.original.list;
        })
        .catch(error => console.log(error))
    },
    getBlocId(bloc){
      this.Emp_info.bloc = bloc.id;
      this.getrooms(bloc.id);
    },
    getAnalytic(bloc){
      this.Emp_info.Emp_structure_id = bloc.code;
      this.Emp_info.Emp_analytic_account = bloc.analyticLists[0].account;
      console.log(this.Emp_info.Emp_structure_id)
    },
    getPostUid(post){
      this.Emp_info.Emp_post = post.uuid;
    },
    getRoomId(room){
      this.Emp_info.room = room.id;
      // this.getrooms(bloc.id);
    },
   
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let loader = this.$loading.show({
              color: '#000000',
              loader: 'spinner',
              width: 64,
              height: 64,
              backgroundColor: '#ffffff',
              opacity: 0.5,
              zIndex: 999,
          })
        this.submitted = false
        this.$http
        .post("/referentiel/employees/store", this.Emp_info)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              // Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$emit('refrechTable', true);
              this.clearForm();
              this.disabled       = true;
              this.disabledCancel = true;
              this.disabledAdd    = false;
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
    deleteEmp() {
      this.destroy = false;
      var _this    = this;
      var typeUid  = this.Emp_info.uuid
      var typeName = this.Emp_info.Emp_first_name +" "+this.Emp_info.Emp_last_name
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'Employé  : " + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/employees/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit('refrechTable', true);
                  this.clearForm();
                  this.lastSelected   = null;
                  break;
                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<style>
  #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteEF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
  .form-check-input{
    font-size: 24px;
  }
</style>
<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card-body">
           <ul id="StickyEF" >
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd && !$can('create_employee')" class="btn btn-addEF rounded-pillEF float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit && !$can('edit_employee')" class="btn btn-editEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledEdit && !$can('delete_employee')" @click="deleteEmp" class="btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
          <h4 class="card-title col-12">
            Details 
          </h4>
          <fieldset :disabled="disabled">
          <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
            <div  icon="fas fa-file-contract" :selected="true" >
                <div class="row">
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Matricule *</label>
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_employee_number" 
                          @blur="$v.Emp_info.Emp_employee_number.$error"
                          :class="{ 'is-invalid': submitted && $v.Emp_info.Emp_employee_number.$error,}" placeholder=""/>
                          <div v-if="submitted && $v.Emp_info.Emp_employee_number.$error" class="invalid-feedback">
                            <span v-if="!$v.Emp_info.Emp_employee_number.required">Le champ est obligatoire.</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Nom *</label>
        
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_first_name" 
                          @blur="$v.Emp_info.Emp_first_name.$error"
                        :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_first_name.$error,
                        }" placeholder=""
                        />
                          <div
                            v-if="submitted && $v.Emp_info.Emp_first_name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.Emp_info.Emp_first_name.required"
                              >Le champ est obligatoire.</span
                            >
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Prénom *</label>
        
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_last_name" 
                          @blur="$v.Emp_info.Emp_last_name.$error"
                        :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_last_name.$error,
                        }" placeholder=""
                        />
                          <div
                            v-if="submitted && $v.Emp_info.Emp_last_name.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.Emp_info.Emp_last_name.required"
                              >Le champ est obligatoire.</span
                            >
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group ">
                        <label for="formrow-inputCity">Sexe * </label>
                          <select class="form-control" v-model="Emp_info.Emp_sexe" 
                        @blur="$v.Emp_info.Emp_sexe.$error"
                        :class="{ 
                          'is-invalid': submitted && $v.Emp_info. Emp_sexe.$error,
                        }">
                            <option v-for="sexe in sexes" :key="sexe.text" :value="sexe.text"> {{sexe.text}} </option>
                        </select>
                          <div
                            v-if="submitted && $v.Emp_info.Emp_sexe.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.Emp_info.Emp_sexe.required"
                              >Le champ est obligatoire.</span>
                          </div>

                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Etat Civile *</label>
                        <select class="form-control"  v-model="Emp_info.Emp_marital" 
                          @blur="$v.Emp_info.Emp_marital.$error"
                        :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_marital.$error,

                        }">
                            <option v-for="etat in etats" :key="etat.text" :value="etat.text"> {{etat.text}} </option>
                        </select>
                          <div
                            v-if="submitted && $v.Emp_info.Emp_marital.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.Emp_info.Emp_marital.required"
                              >Le champ est obligatoire.</span
                            >
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">

                        <label for="formrow-inputCity">Numéro de pièce d'identité *</label>
        
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_n_cin" 
                          @blur="$v.Emp_info.Emp_n_cin.$error"
                        :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_n_cin.$error,
                        }" placeholder=""
                        />
                          <div
                            v-if="submitted && $v.Emp_info.Emp_n_cin.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.Emp_info.Emp_n_cin.required"
                              >Le champ est obligatoire.</span
                            >
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Numéro de passeport *</label>
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_n_passport" 
                          @blur="$v.Emp_info.Emp_n_passport.$error"
                          :class="{'is-invalid': submitted && $v.Emp_info.Emp_n_passport.$error,}" placeholder=""/>
                          <div
                            v-if="submitted && $v.Emp_info.Emp_n_passport.$error" class="invalid-feedback">
                            <span v-if="!$v.Emp_info.Emp_n_passport.required">Le champ est obligatoire.</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Email *</label>
        
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_email" 
                          @blur="$v.Emp_info.Emp_email.$error"
                        :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_email.$error,
                        }" placeholder=""

                        />
                          <div
                            v-if="submitted && $v.Emp_info.Emp_email.$error"
                            class="invalid-feedback"
                          >
                          <span v-if="!$v.Emp_info.Emp_email.required">
                              Le champ obligatoire . </span>
                              
                          <span v-if="!$v.Emp_info.Emp_email.email">
                              entrer un email valide.</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Télèphone *</label>
        
                        <input type="text"  class="form-control" v-model="Emp_info.Emp_phone" 
                          @blur="$v.Emp_info.Emp_phone.$error"
                        :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_phone.$error,
                        }" placeholder=""
                        />
                          <div
                            v-if="submitted && $v.Emp_info.Emp_phone.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.Emp_info.Emp_phone.required">
                              Le champ est obligatoire . </span>
                            <span v-if="!$v.Emp_info.Emp_phone.numeric"
                            >le Numréo de Télèphone doit étre un nombre
                             . </span>
                            
                            <span v-if="!$v.Emp_info.Emp_phone.minLength"
                            >La taille minimum du champs est: 9 nombres
                             . </span>
                            
                            <span v-if="!$v.Emp_info.Emp_phone.maxLength"
                            >La taille maximum du champs est: 10 nombre
                            .</span>
                          </div>
                      </div>
                    </div>
                      <div class="col-xs-12 col-md-4 col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Adresse *</label>
          
                          <input type="text"  class="form-control" v-model="Emp_info.Emp_business_address" 
                            @blur="$v.Emp_info.Emp_business_address.$error"
                          :class="{
                            'is-invalid': submitted && $v.Emp_info.Emp_business_address.$error,
                          }" placeholder=""
                          />
                            <div
                              v-if="submitted && $v.Emp_info.Emp_business_address.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.Emp_info.Emp_business_address.required"
                                >Le champ est obligatoire.</span
                              >
                            </div>
                        </div>
                      </div>
                
                      <div class=" col-xs-12 col-md-4 col-lg-3">
                          <label for="formrow-inputCity">Type de Structure *</label>
          
                          <select class="form-control" @change="getStr()" v-model="strList" 
                          @blur="$v.strList.$error"
                          :class="{
                          'is-invalid': submitted && $v.strList.$error,
                          }">
                              <option v-for="strT in strType" :key="strT.value" :value="strT.value"> {{strT.text}} </option>
                          </select>
                          <div
                              v-if="submitted && $v.strList.$error"
                              class="invalid-feedback">
                              <span v-if="!$v.strList.required"
                              >La champ est obligatoire.</span
                              >
                          </div>
                      </div>
                      <div class="col-xs-12 col-md-4 col-lg-3 ">
                          <label for="formrow-inputCity">Structure *</label>
          
                          <!-- <select class="form-control" v-model="Emp_info.Emp_structure_id" 
                          @blur="$v.Emp_info.Emp_structure_id.$error"
                          :class="{
                          'is-invalid': submitted && $v.Emp_info.Emp_structure_id.$error,
                          }">
                              <option v-for="structure in structures" :key="structure.code" :value="structure.code"> {{structure.name}} </option>
                          </select> -->
                          <multiselect @blur="$v.Emp_info.Emp_structure_id.$error"
                          :class="{
                            'is-invalid': submitted && $v.Emp_info.Emp_structure_id.$error,
                          }" v-model="structure" :options="structures" track_by="code" :searchable="true" :show-labels="false" :allow-empty="false" label="name" @select="getAnalytic">
                            <!-- <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> </template> -->
                          </multiselect>
                          <div
                              v-if="submitted && $v.Emp_info.Emp_structure_id.$error"
                              class="invalid-feedback">
                              <span v-if="!$v.Emp_info.Emp_structure_id.required"
                              >La champ est obligatoire.</span
                              >
                          </div>
                      </div>
                      <div class="col-xs-6 col-md-6 col-lg-3">
                        <div class="form-group">
                          <label for="formrow-email-input">Poste *</label>
                          <!-- <select v-model="Emp_info.Emp_post" class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.Emp_info.Emp_post.$error,
                              'isDisabled disabledBG':disabled
                            }">
                            <option v-for="post in posts" :key="post.uuid" :value="post.uuid"> {{post.designation}} </option>
                          </select> -->
                          <multiselect :class="{
                              'is-invalid': submitted && $v.Emp_info.Emp_post.$error,
                              'isDisabled disabledBG':disabled
                            }" v-model="post" :options="posts" track_by="uuid" :searchable="true" :show-labels="false" :allow-empty="false" label="designation" @select="getPostUid">
                            <!-- <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> </template> -->
                          </multiselect>
                          
                          <div v-if="submitted && $v.Emp_info.Emp_post.$error"
                            class="invalid-feedback">
                            <span v-if="!$v.Emp_info.Emp_post.required">
                              Le champ est obligatoire.</span>

                          </div>
                        </div>
                      </div>
                      <div class="col-xs-6 col-md-6 col-lg-3">
                        <div class="form-group">
                          <label for="formrow-email-input">Nationalité *</label>
                          <select v-model="Emp_info.nationality" class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.Emp_info.nationality.$error,
                              'isDisabled disabledBG':disabled
                            }">
                            <option v-for="nat in nationalitys" :key="nat.uuid" :value="nat.uuid"> {{nat.designation}} </option>
                          </select>
                          
                          <div v-if="submitted && $v.Emp_info.nationality.$error"
                            class="invalid-feedback">
                            <span v-if="!$v.Emp_info.nationality.required">
                              Le champ est obligatoire.</span>

                          </div>
                        </div>
                      </div>
                      
                      <div class="col-xs-12 col-md-4 col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Compte Analytique </label>
          
                          <input type="text"  class="form-control" v-model="Emp_info.Emp_analytic_account" 
                            placeholder=""
                          />
                          
                        </div>
                      </div>
                      <div class="col-xs-6 col-md-4 col-lg-1  mt-4">
                        <b-form-checkbox
                            v-model="Emp_info.Emp_is_externe"
                            switch  :value="1"
                            class="mb-3"
                            >Résidant</b-form-checkbox
                          >
                      </div>
                      <div class="col-xs-6 col-md-4 col-lg-1  mt-4">
                        <b-form-checkbox
                            v-model="Emp_info.Emp_is_user"
                            switch  :value="1"
                            class="mb-3"
                            >Utilisateur</b-form-checkbox
                          >
                      </div>
                      <div v-if="Emp_info.Emp_is_externe" class="col-xs-12 col-md-4 col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Bloc</label>
          
                          <multiselect v-model="bloc" :options="blocs" track_by="id" :searchable="true" :show-labels="false" :allow-empty="false" label="name" @select="getBlocId">
                            <!-- <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> </template> -->
                          </multiselect>
                          
                        </div>
                      </div>
                      <div v-if="Emp_info.Emp_is_externe" class="col-xs-12 col-md-4 col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity">Chambre</label>
          
                          <multiselect v-model="room" :options="rooms" track_by="id" :searchable="true" :reset-after="false" :allow-empty="false" label="number" @select="getRoomId"></multiselect>
                          
                        </div>
                      </div>
                </div>
              <!-- end row -->
            </div>
            <div class="row">
              <div class="col-md-12" v-show="!disabled && ($can('create_employee') || $can('edit_employee'))"> 
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                
                <button type="submit" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
                
              </div>
            </div>  
          </form>
          </fieldset>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
